import React, { useEffect, useRef } from "react";
import CollisionDetector from "../../lib/CollisionDetector";

interface Props {
  locale: string;
  showNav: boolean;
}

export default function HeaderLogo(props: Props) {
  const logoRef = useRef();

  useEffect(() => {
    const elem = logoRef.current;

    if (elem && !props.showNav) {
      CollisionDetector.addHideable(elem);
    }
    return function cleanup() {
      if (elem) {
        CollisionDetector.removeHideable(elem);
      }
    };
  });

  return (
    <div className="logo" ref={logoRef}>
      <a href={`/${props.locale}/pages`}>
        <span>Møre og Romsdal Kunstsenter</span>
      </a>
    </div>
  );
}
