import React from "react";

type Props = NavPage;

export default function NavItem(props: Props) {
  return (
    <li key={props.id}>
      <a href={props.url} className={props.current ? "current" : ""}>
        {props.name}
      </a>
      {props.pages.length > 0 && (
        <ul className="subnav">
          {props.pages.map((page) => (
            <NavItem key={page.id} {...page} />
          ))}
        </ul>
      )}
    </li>
  );
}
