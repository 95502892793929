import React, { useState } from "react";

import Event from "./Event";

interface Props {
  label: string;
  events: ArchiveEvent[];
  expandable?: boolean;
}

export default function Section(props: Props) {
  const [expandedState, setExpanded] = useState(false);
  let expanded = true;

  const toggleExpanded = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setExpanded(!expanded);
  };

  const classes = ["archive-section"];

  if (props.expandable) {
    expanded = expandedState;
    classes.push("expandable");
    if (expanded) {
      classes.push("expanded");
    }
  }

  return (
    <div className={classes.join(" ")}>
      <h3>
        {props.expandable && (
          <a href="#" onClick={toggleExpanded}>
            {props.label}
          </a>
        )}
        {!props.expandable && props.label}
      </h3>
      {expanded && (
        <div className="events">
          {props.events.map((event: ArchiveEvent) => (
            <Event key={event.id} event={event} />
          ))}
        </div>
      )}
    </div>
  );
}
