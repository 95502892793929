import { Controller } from "stimulus";
import CollisionDetector from "../lib/CollisionDetector";

export default class extends Controller {
  hasCollidableTarget: boolean;
  collidableTargets: HTMLElement[];
  hasHideableTarget: boolean;
  hideableTargets: HTMLElement[];

  static get targets() {
    return ["hideable", "collidable"];
  }

  connect() {
    if (!this.hasCollidableTarget || !this.hasHideableTarget) {
      return;
    }

    this.collidableTargets.forEach((e) => CollisionDetector.addCollidable(e));
    this.hideableTargets.forEach((e) => CollisionDetector.addHideable(e));
  }

  disconnect() {
    this.collidableTargets.forEach((e) =>
      CollisionDetector.removeCollidable(e)
    );
    this.hideableTargets.forEach((e) => CollisionDetector.removeHideable(e));
  }
}
