import React from "react";

interface Props {
  event: ArchiveEvent;
}

export default function Event(props: Props) {
  const event = props.event;

  return (
    <a href={event.url} className="archive-event">
      <span className="event-time">{event.time}</span>
      {event.type == "exhibition" && (
        <span className="name">
          {event.artist}
          <br />
          <i>{event.name}</i>
        </span>
      )}
      {event.type == "event" && <span className="name">{event.name}</span>}
    </a>
  );
}
