import React, { useEffect, useRef, useState } from "react";
import WindowAnimation from "../lib/WindowAnimation";

import HeaderLogo from "./Header/HeaderLogo";
import Nav from "./Header/Nav";
import NavToggle from "./Header/NavToggle";

interface Props {
  locale: string;
  locales: [string, string][];
  nav: Record<string, NavPage[]>;
  strings: Record<string, Record<string, string>>;
}

export default function Header(props: Props) {
  const headerRef = useRef<HTMLElement>();
  const [showNav, setShowNav] = useState(false);

  const toggleNav = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setShowNav(!showNav);
  };

  const classList = [];
  if (showNav) {
    classList.push("expanded");
  }

  useEffect(() => {
    let headerHeight = 0;

    const handle = WindowAnimation.register({
      resize: () => {
        if (headerRef.current) {
          headerHeight = headerRef.current.getBoundingClientRect().height;
        }
      },
      scroll: (offset) => {
        if (headerRef.current) {
          if (offset > headerHeight + 10 && !showNav) {
            headerRef.current.classList.add("scrolled");
          } else {
            headerRef.current.classList.remove("scrolled");
          }
        }
      }
    });

    return function cleanup() {
      WindowAnimation.clear(handle);
    };
  });

  return (
    <header className={classList.join(" ")} ref={headerRef}>
      <div className="inner">
        <HeaderLogo showNav={showNav} locale={props.locale} />
        <NavToggle
          strings={props.strings[props.locale]}
          showNav={showNav}
          toggleNav={toggleNav}
        />
        {showNav && (
          <Nav locale={props.locale} locales={props.locales} nav={props.nav} />
        )}
      </div>
    </header>
  );
}
