import React, { useState } from "react";

import NavItem from "./NavItem";

interface Props {
  locale: string;
  locales: [string, string][];
  nav: Record<string, NavPage[]>;
}

export default function Nav(props: Props) {
  const [currentLocale, setCurrentLocale] = useState(props.locale);

  const handleLocale = (locale: string) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    setCurrentLocale(locale);
  };

  const navItems = props.nav[currentLocale];

  return (
    <nav>
      <ul className="locales">
        {Object.entries(props.locales).map((entry) => (
          <li key={entry[0]}>
            <a
              href={`/${entry[0]}/pages`}
              onClick={handleLocale(entry[0])}
              className={currentLocale == entry[0] ? "current" : ""}>
              {entry[1]}
            </a>
          </li>
        ))}
      </ul>
      <ul>
        {navItems.map((page) => (
          <NavItem key={page.id} {...page} />
        ))}
      </ul>
    </nav>
  );
}
