import React, { useEffect } from "react";

interface Props {
  image: Image;
  navigate: (offset: number) => void;
  closeLightbox: () => void;
  strings: Record<string, string>;
}

export default function Lightbox(props: Props) {
  const image = props.image;

  const handleClose = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.closeLightbox();
  };

  const handleNavigate = (offset: number) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.navigate(offset);
  };

  const handleKeypress = (evt: KeyboardEvent) => {
    if (evt.which == 27) {
      props.closeLightbox();
    } else if (evt.which == 39) {
      props.navigate(1);
    } else if (evt.which == 37) {
      props.navigate(-1);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeypress);
    };
  });

  return (
    <div className="lightbox">
      <div className="inner">
        <div className="top">
          <button className="close" onClick={handleClose}>
            {props.strings.close}
          </button>
        </div>
        <div className="middle">
          <button className="previous" onClick={handleNavigate(-1)}>
            Previous
          </button>
          <div className="content">
            <div className="image">
              <img key={image.id} src={image.full} alt={image.alt} />
            </div>
            {image.caption && <figcaption>{image.caption}</figcaption>}
          </div>
          <button className="next" onClick={handleNavigate(1)}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
