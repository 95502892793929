import React, { useState } from "react";

import Lightbox from "./ImageGallery/Lightbox.jsx";
import Thumbnail from "./ImageGallery/Thumbnail.jsx";

interface Props {
  images: Image[];
  strings: Record<string, string>;
}

export default function ImageGallery(props: Props) {
  const images = props.images;

  const [currentImage, setCurrentImage] = useState<Image>(null);

  const closeLightbox = () => {
    setCurrentImage(null);
  };

  const showImage = (image: Image) => {
    setCurrentImage(image);
  };

  const navigate = (offset) => {
    if (currentImage) {
      let index = (images.indexOf(currentImage) + offset) % images.length;
      if (index < 0) {
        index += images.length;
      }
      setCurrentImage(images[index]);
    }
  };

  return (
    <div className="image-gallery">
      {currentImage && (
        <Lightbox
          image={currentImage}
          closeLightbox={closeLightbox}
          strings={props.strings}
          navigate={navigate}
        />
      )}
      <div className="images">
        {images.map((image) => (
          <Thumbnail key={image.id} image={image} showImage={showImage} />
        ))}
      </div>
    </div>
  );
}
