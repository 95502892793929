import React from "react";

interface Props {
  strings: Record<string, string>;
  showNav: boolean;
  toggleNav: (evt: React.MouseEvent) => void;
}

export default function NavToggle(props: Props) {
  const label = props.showNav ? props.strings.close : props.strings.menu;

  return (
    <div className="nav-toggle">
      <button onClick={props.toggleNav}>{label}</button>
    </div>
  );
}
