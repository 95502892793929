import React from "react";

import Section from "./Archive/Section";

interface Props {
  events: Record<string, ArchiveEvent[]>;
  expandable?: boolean;
}

export default function Archive(props: Props) {
  const expandable = props.expandable || false;
  const years = Object.keys(props.events).sort().reverse();

  return (
    <div className="archive-list">
      {years.map((year) => (
        <Section
          expandable={expandable}
          key={year}
          label={year}
          events={props.events[year]}
        />
      ))}
    </div>
  );
}
