import WindowAnimation from "./WindowAnimation";

class CollisionDetector {
  handle: number | null;
  collidable: HTMLElement[];
  hideable: HTMLElement[];

  constructor() {
    this.hideable = [];
    this.collidable = [];
  }

  start() {
    this.handle = WindowAnimation.register({
      scroll: () => {
        this.hideable.forEach((hideable) => {
          const hideableRect = hideable.getBoundingClientRect();
          let intersecting = false;

          this.collidable.forEach((collidable) => {
            const collidableRect = collidable.getBoundingClientRect();

            if (this.intersect(hideableRect, collidableRect)) {
              intersecting = true;
            }
          });

          if (intersecting) {
            hideable.classList.add("hidden");
          } else {
            hideable.classList.remove("hidden");
          }
        });
      }
    });
  }

  stop() {
    if (this.handle) {
      WindowAnimation.clear(this.handle);
    }
  }

  intersect(r1: DOMRect, r2: DOMRect) {
    const horizontalMargin = 3;
    const verticalMargin = 64;

    return !(
      r2.left > r1.right + horizontalMargin ||
      r2.right < r1.left - horizontalMargin ||
      r2.top > r1.bottom + verticalMargin ||
      r2.bottom < r1.top - verticalMargin
    );
  }

  addHideable(elem: HTMLElement) {
    elem.classList.add("hideable");
    this.hideable.push(elem);
  }

  addCollidable(elem: HTMLElement) {
    this.collidable.push(elem);
  }

  removeHideable(elem: HTMLElement) {
    elem.classList.remove("hidden");
    elem.classList.remove("hideable");
    this.hideable = this.hideable.filter((e) => e !== elem);
  }

  removeCollidable(elem: HTMLElement) {
    this.collidable = this.collidable.filter((e) => e !== elem);
  }
}

export default new CollisionDetector();
