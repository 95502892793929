import React from "react";

interface Props {
  image: Image;
  showImage: (img: Image) => void;
}

export default function Thumbnail(props: Props) {
  const image = props.image;

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.showImage(image);
  };

  return (
    <a href={image.full} className="thumbnail" onClick={handleClick}>
      <img src={image.thumbnail} alt={image.alt} />
    </a>
  );
}
